import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state?: string;
  name?: string;
  type: string;
  icon?: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  {
    type: 'title',
    name: 'MAIN'
  },
  // Admin menu items begin here ................................................
  //.............................................................................

  // {
  //   state: '/',
  //   name: 'Administration Portal',
  //   type: 'link',
  //   icon: 'ion-ios-speedometer'
  // },

  // {
  //   type: 'divider'
  // },


  // {
  //   state: 'accounting', // SEE APP.ROUTING.TS.......this state is called there
  //   name: 'ACCOUNTING',
  //   type: 'sub',
  //   icon: 'ion-cash',
  //   children: [
  //     {
  //       state: 'viewfinace',
  //       name: 'Finance | Dashboard'
  //     },
      
  //     {
  //       state: 'allstokvelchiefs',
  //       name: 'All Stokvel Chiefs'
  //     }

  //   ]
  // },
  
  // {
  //   type: 'divider'
  // },

  // Admin Menu items end Here ..................................................................
  //................................................................................................

  {
    state: '/',
    name: 'HOME',
    type: 'link',
    icon: 'ion-ios-speedometer'
  },

  {
    type: 'divider'
  },

  {
    state: 'club', // SEE APP.ROUTING.TS.......this state is called as a PATH there
    name: 'myStokvels',
    type: 'sub',
    icon: 'ion-ios-people',
    children: [
      {
        state: 'newclub',
        name: 'Add New Stokvel'
      },
      // {
      //   state: 'editclub',//remove space to make uri work
      //   name: 'Edit Club'
      // },
      {
        state: 'allclubs',
        name: 'All Stokvels | Members'
      }
    ]
  },

  {
    type: 'divider'
  },

  {
    state: 'meeting', // SEE APP.ROUTING.TS.......this state is called there
    name: 'myMEETINGS',
    type: 'sub',
    icon: 'ion-ios-people',
    children: [
      // {
      //   state: 'meetingnew',
      //   name: 'Add New Meeting'
      // },
      // {
      //   state: 'meetingedit',
      //   name: 'Edit Meeting'
      // },
      {
        state: 'meetingall', //         state: '  meetingall', //due to those space, route wont resolve
        name: 'All Meeetings'
      },
      // {
      //   state: '',
      //   name: 'Meeting Calender'
      // },
    ]
  },

  {
    type: 'divider'
  },

  {
    state: 'member', // SEE APP.ROUTING.TS.......this state is called there
    name: 'MEMBERS',
    type: 'sub',
    icon: 'ion-ios-people',
    children: [
      {
        state: 'newmember',
        name: 'Add New Member(s)'
      },
      // {
      //   state: 'editmember',
      //   name: 'Edit Member'
      // },

      // {
      //   state: 'allmembers',
      //   name: 'All Members'
      // }
    ]
  },
  // {
  //   state: 'accounting', // SEE APP.ROUTING.TS.......this state is called there
  //   name: 'ACCOUNTING',
  //   type: 'sub',
  //   icon: 'ion-cash',
  //   children: [
  //     {
  //       state: 'viewfinace',
  //       name: 'Finance | Dashboard'
  //     },
      
  //     {
  //       state: 'allstokvelchiefs',
  //       name: 'All Stokvel Chiefs'
  //     }

  //   ]
  // },
  
  {
    type: 'divider'
  },
  // {
  //   state: 'logout',
  //   name: 'Logout',
  //   type: 'link',
  //   icon: 'ion-ios-speedometer'
  // },

];

@Injectable()
export class MenuService {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
