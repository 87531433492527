import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service'
import { SmsVerificationService } from '../../core/sms.verification.service';
import { WindowService } from '../../core/window.service';
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery'; //include to use ...then initiate jquery code in ngOnInit(){....}
import * as firebase from 'firebase';
import swal from 'sweetalert2'

// CommonJS
export interface captchaDemoModel {
  captcha?: string;
}


@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})

export class RegisterComponent implements OnInit {
  windowRef: any;
  public formModel: captchaDemoModel = {};

  registerForm: FormGroup;
  verifyForm: FormGroup;
  errorMessage: string = '';
  successMessage: string = '';
  submitted = false;
  verificationCode: string;
  public sent: boolean;
  constructor(
    private win: WindowService,
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) {
    // this.createForm();
  }

  public ngOnInit() {
    this.windowRef = this.win.windowRef
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container')
    this.windowRef.recaptchaVerifier.render()
    this.createForm();

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    //jQuery time
    var current_fs, next_fs, previous_fs; //fieldsets
    var left, opacity, scale; //fieldset properties which we will animate
    var animating; //flag to prevent quick multi-click glitches

    $(".next").click(function () {
      if (animating) return false;
      animating = true;

      current_fs = $(this).parent();
      next_fs = $(this).parent().next();

      //activate next step on progressbar using the index of next_fs
      $("#progressbar li").eq($("fieldset").index(next_fs)).addClass("active");

      //show the next fieldset
      next_fs.show();
      //hide the current fieldset with style
      current_fs.animate({ opacity: 0 }, {
        step: function (now, mx) {
          //as the opacity of current_fs reduces to 0 - stored in "now"
          //1. scale current_fs down to 80%
          scale = 1 - (1 - now) * 0.2;
          //2. bring next_fs from the right(50%)
          left = (now * 50) + "%";
          //3. increase opacity of next_fs to 1 as it moves in
          opacity = 1 - now;
          current_fs.css({
            'transform': 'scale(' + scale + ')',
            'position': 'absolute'
          });
          next_fs.css({ 'left': left, 'opacity': opacity });
        },
        duration: 800,
        complete: function () {
          current_fs.hide();
          animating = false;
        },
        //this comes from the custom easing plugin
        // easing: 'easeInOutBack'
      });
    });

    $(".previous").click(function () {
      if (animating) return false;
      animating = true;

      current_fs = $(this).parent();
      previous_fs = $(this).parent().prev();

      //de-activate current step on progressbar
      $("#progressbar li").eq($("fieldset").index(current_fs)).removeClass("active");

      previous_fs.show();
      //hide the current fieldset with style
      current_fs.animate({ opacity: 0 }, {
        step: function (now, mx) {
          //as the opacity of current_fs reduces to 0 - stored in "now"
          scale = 0.8 + (1 - now) * 0.2;
          //2. take current_fs to the right(50%) - from 0%
          left = ((1 - now) * 50) + "%";
          opacity = 1 - now;
          current_fs.css({ 'left': left });
          previous_fs.css({ 'transform': 'scale(' + scale + ')', 'opacity': opacity });
        },
        duration: 800,
        complete: function () {
          current_fs.hide();
          animating = false;
        },
        //this comes from the custom easing plugin
        // easing: 'easeInOutBack'
      });
    });



  }



  createForm() {
    this.registerForm = this.fb.group({
      title: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', Validators.required],
      verificationCode: '',
      //  recaptchaVerifierCode: this.windowRef.recaptchaVerifier,
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      idnumber: ['', Validators.required],
      address: ['', Validators.required],
      altnumber: '',
      cellnumber: ['', Validators.required],
      province: ['', Validators.required],
      privacypolicy: ['', Validators.required],
      userRole: 'stokvelchief',


    });
  }


  tryFacebookLogin() {
    this.authService.doFacebookLogin()
      .then(res => {
        this.router.navigate(['/dashboard']);
      }, err => console.log(err)
      )
  }

  tryTwitterLogin() {
    this.authService.doTwitterLogin()
      .then(res => {
        this.router.navigate(['/dashboard']);
      }, err => console.log(err)
      )
  }

  tryGoogleLogin() {
    this.authService.doGoogleLogin()
      .then(res => {
        this.router.navigate(['/dashboard']);
      }, err => console.log(err)
      )
  }

  get formData() { return this.registerForm.controls; }

  //  tryRegister(value){
  //   this.submitted = true;
  //   if (this.registerForm.invalid) {
  //       return;
  //   }
  //    this.authService.doRegister(value)
  //    .then(res => {
  //     this.windowRef.confirmationResult = res;
  //      console.log(res);
  //      this.errorMessage = "";
  //      this.successMessage = "Your account has been created";
  //      this.router.navigate(['/dashboard']);

  //    }, err => {
  //      console.log(err);
  //      this.errorMessage = err.message;
  //      this.successMessage = "";
  //    })
  //  }

  //  onSubmit(value){
  //   this.submitted = true;
  //   if (this.registerForm.invalid) {
  //       return;
  //   }
  //    this.authService.doRegister(value)
  //    .then(res => {
  //     this.windowRef.confirmationResult = res;
  //      console.log(res);
  //      this.errorMessage = "";
  //      this.successMessage = "Your account has been created";
  //      this.router.navigate(['/dashboard']);

  //    }, err => {
  //      console.log(err);
  //      this.errorMessage = err.message;
  //      this.successMessage = "";
  //    })
  // }

  onSubmit(value) {//value is our parameter passed from ui
    this.submitted = true;
    let countryCode = '+27'
    let phoneNumber = countryCode + value.cellnumber
    const appVerifier = this.windowRef.recaptchaVerifier;
    if (this.registerForm.invalid) {
      return;
    }
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
        .then((confirmationResult) => {

          this.sent = true;
          swal({
            title: 'Enter OTP Code',
            input: 'text'
          })
            .then(function (otp) {
              swal({
                type: 'success',
                html: 'Your code: ' + otp.value
              })
              console.log("Ubunye: " + otp.value);
              confirmationResult.confirm(otp.value)
                .then((good) => {
                  // all checks out
                  let user = firebase.auth().currentUser;
                  // user.updateProfile({ displayName: value.firstname, photoURL: "https://example.com/jane-q-user/profile.jpg" })
                  user.updateEmail(value.email).then(function () {
                    user.updatePassword(value.password)

                      .then(function () {
                        console.log("password updated")                        /** send email verification*/
                        user.sendEmailVerification().then(function () {
                          console.log("verification email sent")
                          firebase.firestore().collection("stokvelchiefs").doc(user.uid).set(
                            {//NB: if no value is passed from UI fields, no data is saved
                              title: value.title,
                              firstname: value.firstname,
                              lastname: value.lastname,
                              email: value.email,// pulled from registered user facebook credentials
                              cellnumber: value.cellnumber,
                              altnumber: value.altnumber,
                              idnumber: value.idnumber,
                              address: value.address,
                              province: value.province,
                              privacypolicy: value.privacypolicy,
                              emailverified: user.emailVerified,
                              roles: "stokvelchief",
                              idnumberVerified: false,
                              documentsVeried: false,
                            })
                        }).catch(function (error) { console.log(error) });


                      }).catch(function (error) { console.log(error) });
                  }).catch(function (error) { });

                  console.log(user);
                  swal("Success: Ubunye Account Exists !")
                  .then((value) => {
                    swal(`redirecting to ubunye.....`);
                    location.reload();
                  });

                })

                .catch((bad) => {
                  // code verification was bad.
                  swal({
                    type: 'error',
                    html: 'Error: ' + bad.code //+ "3"+bad.message
                  })
                });

            })
        });
    })

      .catch(function (error) {
        // Error; SMS not sent
        // ...
        //  return alert('Error ! SMS not sent')
        swal({
          type: 'error',
          html: 'Error... re-try ' 
        })
      })



  }



}
