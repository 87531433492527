import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
// import { SigninComponent } from './account/signin/signin.component';
// import { SignupComponent } from './account/signup/signup.component';
import { ForgotComponent } from './account/forgot/forgot.component';
import { AngularFireModule } from 'angularfire2';
import { AngularFirestoreModule } from 'angularfire2/firestore';
import { AngularFireAuthModule } from 'angularfire2/auth';
import { environment } from '../environments/environment';
import { LoginComponent } from './account/login/login.component';
import { RegisterComponent } from './account/register/register.component';
import { UserResolver } from './core/user.resolver';
// import { MemberEditComponent } from './_member/member.edit/member.edit.component';
// import { EditMemberResolver } from './_member/member.edit/member.edit.resolver';
// import { AvatarDialogComponent } from './_member/avatar-dialog/avatar-dialog.component';
import { AuthGuard } from './core/auth.guard';
import { AuthService } from './core/auth.service';
import { UserService } from './core/user.service';
import { SmsVerificationService } from './core/sms.verification.service';
import { SmsVerificationDialogComponent } from './core/sms.verification.dialog/sms.verification.dialog.component';
import { WindowService } from './core/window.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatSidenavModule } from '@angular/material';

import { LoadingBarRouterModule } from '@ngx-loading-bar/router';

import { AppRoutes } from './app.routing';
import { AppComponent } from './app.component';
import { FirebaseService } from './core/firebase.service';
import {MatButtonModule, MatInputModule, MatSliderModule, MatDialogModule,   MatSortModule,   MatTableModule,  MatFormFieldModule} from '@angular/material';
import {DataTableModule} from "angular-6-datatable";
import {
  MenuComponent,
  HeaderComponent,
  SidebarComponent,
  FooterComponent,
  AdminLayoutComponent,
  AuthLayoutComponent,
  // SigninComponent,
  AccordionAnchorDirective,
  AccordionLinkDirective,
  AccordionDirective
} from './core';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [/////////////// declare your components
    AppComponent,
    MenuComponent,
    HeaderComponent,
    SidebarComponent,
    FooterComponent,
    AdminLayoutComponent,
    SmsVerificationDialogComponent,
    AuthLayoutComponent,
    // SigninComponent,
    // SignupComponent,
    ForgotComponent,
    LoginComponent,
    RegisterComponent,
    // AvatarDialogComponent,
    // MemberEditComponent,
    AccordionAnchorDirective,
    AccordionLinkDirective,
    AccordionDirective
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(AppRoutes),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features
    DataTableModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatSortModule,
    MatDialogModule,
    MatButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LoadingBarRouterModule,
    NgbModule.forRoot(),
    MatSidenavModule
  ],
  providers: [FirebaseService, AuthService, UserService, SmsVerificationService, WindowService, UserResolver, AuthGuard],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class AppModule {}
