import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../core/auth.service'

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss']
})
export class ForgotComponent {

  resetEmailForm: FormGroup;
  errorMessage: string = '';
  successMessage: string = '';

  constructor(
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder
  ) {
    this.createForm();
  }

  createForm() {
    this.resetEmailForm = this.fb.group({
      email: ['', Validators.required ]
    });
  }



  tryResetEmailPassword(value){
    this.authService.doResetEmailPassword(value)
    .then(res => {
      console.log(res);
      // this.errorMessage = "Ubunye user: Your email may be incorrect. However..... ";
      this.successMessage = "Ubunye user: a reset link has been sent to you email account.";

    }, err => {
      console.log(err);
      this.errorMessage = err.message;
      // this.successMessage = "a reset link has been sent to you email account.";
    })
  }

//   trysendEmailVerification(value){
//   // var user = firebase.auth().currentUser;
//   this.authService.sendEmailVerification(value)
//   .then(function() {
//   // Email sent.
// }).catch(function(error) {
//   // An error happened.
// });
// }
  // onSubmit() {
  //   this.router.navigate(['/']);
  // }
}
