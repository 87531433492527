// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    // apiKey: "AIzaSyDm83KGJaCcLrTINfZw_4zZ-Z7W5A7_Yj4",
    // authDomain: "ubunye-dc0ca.firebaseapp.com",
    // databaseURL: "https://ubunye-dc0ca.firebaseio.com",
    // projectId: "ubunye-dc0ca",
    // storageBucket: "ubunye-dc0ca.appspot.com",
    // messagingSenderId: "150909945057"
    apiKey: "AIzaSyA12_IITkByEfNuodjldvqNzBBPdVUVEWE",
    authDomain: "ubunyestokvel2018.firebaseapp.com",
    databaseURL: "https://ubunyestokvel2018.firebaseio.com",
    projectId: "ubunyestokvel2018",
    storageBucket: "ubunyestokvel2018.appspot.com",
    messagingSenderId: "801798125862"
  }
};
