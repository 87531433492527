import { Injectable } from "@angular/core";
import 'rxjs/add/operator/toPromise';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';

@Injectable()
export class AuthService {

  constructor(
   public afAuth: AngularFireAuth
 ){

 }

  doFacebookLogin(){
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.FacebookAuthProvider();
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      })
    })
  }

  doTwitterLogin(){
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.TwitterAuthProvider();
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      })
    })
  }

  doGoogleLogin(){
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(res => {
        resolve(res);
      }, err => {
        console.log(err);
        reject(err);
      })
    })
  }

  doGoogleRegister(){
    return new Promise<any>((resolve, reject) => {
      let provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      this.afAuth.auth
      .signInWithPopup(provider)
      .then(() => {
        const user = firebase.auth().currentUser;
          var userRef = firebase.firestore().collection("users/").doc(user.uid);
          userRef.set(
            {
              name: user.displayName,
              lastname:'',
              email: user.email,
              cellnumber: user.phoneNumber,
              idnumber:'',
              idtype:'', 
              address:'',
              address2:'',
              province:'',
              city:'',
              zip:'',
              emailverified:user.emailVerified,
              userRole:''
            })
          .then(res =>{ // remember to do a resolve here since we need ubunye user to be redirected to dashboard
            resolve(res);
          })
         
      }, err => {
        console.log(err);
        reject(err);
      })
    })
  }

  doRegister(value){//value is our parameter passed from ui
    return new Promise<any>((resolve, reject) => {
      firebase.auth().createUserWithEmailAndPassword(value.email, value.password)
     .then(res =>{ // remember to do a resolve here since we need ubunye user to be redirected to dashboard
              resolve(res);
            }) 
            .then(() => {
        const user = firebase.auth().currentUser;
          var userRef = firebase.firestore().collection("stokvelchiefs").doc(user.uid);
          userRef.set(
            {//NB: if no value is passed from UI fields, no data is saved
              title: value.title,
              firstname:value.firstname,
              lastname:value.lastname,
              email: value.email,// pulled from registered user facebook credentials
              cellnumber: value.cellnumber,
              altnumber:value.altnumber,
              idnumber:value.idnumber,
              address:value.address,
              province:value.province,
              privacypolicy:value.privacypolicy,
              emailverified:user.emailVerified,
              roles: "stokvelchief",
              idnumberVerified:false,
              documentsVeried:false,
            })
            // .then(res =>{ // remember to do a resolve here since we need ubunye user to be redirected to dashboard
            //   resolve(res);
            // })
      }, err => {
        console.log(err);
        reject(err);
      })
      
    })
  }



  doResetEmailPassword(value){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().sendPasswordResetEmail(value.email)
      .then(res => {
        resolve(res);
      }, err => reject(err))
    })
  }
  
  doLogin(value){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithEmailAndPassword(value.email, value.password)
      .then(res => {
        resolve(res);
      }, err => reject(err))
    })
  }

  doLoginPhone(value){
    return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithPhoneNumber(value.cellnumber, value.password)
      .then(res => {
        resolve(res);
      }, err => reject(err))
    })
  }
  doLogout(){
    return new Promise((resolve, reject) => {
      if(firebase.auth().currentUser){
        this.afAuth.auth.signOut()
        resolve();
      }
      else{
        reject();
      }
    });
  }


}
