import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './core';
import { AuthLayoutComponent } from './core';
import { LoginComponent } from './account/login/login.component';
import { RegisterComponent } from './account/register/register.component';
import { ForgotComponent } from './account/forgot/forgot.component';

import { UserResolver } from './core/user.resolver';
import { AuthGuard } from './core/auth.guard';


export const AppRoutes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [AuthGuard] },
  { path: 'register', component: RegisterComponent, canActivate: [AuthGuard] },
  { path: 'forgot', component: ForgotComponent, canActivate: [AuthGuard] },

  {
    path: '',
    component: AdminLayoutComponent,// this layout allo0ws sidebar and header to be visible upon nav
    resolve: { data: UserResolver},// here we do a user if 'auth' resolve else redirect to login

    children: [
      {
        path: 'dashboard',
        loadChildren: './dashboard/dashboard.module#DashboardModule',
      },
      {
        path: 'pages',
        loadChildren: './pages/pages.module#PagesModule'
      },
      {
        path: 'profile',
        loadChildren: './profile/profile.module#ProfileModule'
      },
      {
        path: 'club', // path to state // see menu.service.ts
        loadChildren: './_club/club.module#ClubModule'
      },

      {
        path: 'member',
        loadChildren: './_member/member.module#MemberModule'
      },
      {
        path: 'meeting',
        loadChildren: './_meeting/meeting.module#MeetingModule'
      },
      // Admin routing begins here............................................................
      //......................................................................................

      {
        path: 'accounting',
        loadChildren: './_accounting/stokvelchief.module#StokvelchiefModule'
      },

      // Admin routing ends here.............................................................
      //.....................................................................................
      
      {
        path: 'error',
        loadChildren: './error/error.module#ErrorModule'
      }
    ]
  },
 ////////////////////////these routes defined BELOW below WILL LOAD AND wont show header and footer...dont ask me!
 /////////////////////// CERTAINLY, LOGIN PAGES AND SIGNUP THERE IS NO NEED FOR HEADER AND SIDEBAR ETC
  // {
  //   path: '',
  //   component: AuthLayoutComponent,
  //   children: [
      
  //     {
  //       path: 'account', // path to state // see menu.service.ts
  //       loadChildren: './account/account.module#AccountModule'
  //     }
  //   ]
  // },

  {
    path: '**',
    redirectTo: 'error/404'
  }
];
