  
import { Component, ViewChild, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UserService } from '../user.service';
import { AuthService } from '../auth.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseUserModel } from '../user.model';
import { FirebaseService } from '../../core/firebase.service';
import { Router } from '@angular/router';
import * as firebase from 'firebase/app';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit{
    @Output()
  messageEvent = new EventEmitter<Object>();
  @Output()
  toggleFullscreen = new EventEmitter<void>();

  user: FirebaseUserModel = new FirebaseUserModel();
  profileForm: FormGroup;
  item: any;

  options = {
    lang: 'en',
    theme: 'summer',
    settings: false,
    docked: false,
    boxed: false,
    opened: false
  };


  constructor(
    public userService: UserService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private location : Location,
    public translate: TranslateService,
    public firebaseService: FirebaseService,
    private router: Router,
    private fb: FormBuilder
  ) {
 const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
  }


  ngOnInit(){

   
    this.getData();

  }

 
  
  getData(){

    return new Promise((resolve, reject) => {
      let useruid = firebase.auth().currentUser.uid; // get uid from auth then pass that as parameter
      this.firebaseService.getProfile(useruid)
      .subscribe(
        data => {
          this.item = data.payload.data();
          this.item.address ;
          
          console.log(data.payload.data())
          console.log("Current User PhoneNumber:" + firebase.auth().currentUser.email)
            }
      );
    })

  }

  // getData(){

  //   return new Promise((resolve, reject) => {
  //     let useruid = firebase.auth().currentUser.uid; // get uid from auth then pass that as parameter
  //     this.firebaseService.getProfile(useruid)
  //     .subscribe(
  //       data => {
  //        resolve(data)
  //        console.log(data)
  //           }
  //     );
  //   })
  // }

  createForm(name) {
    this.profileForm = this.fb.group({
      name: [name, Validators.required ]
    });
  }
  sendMessage() {
    this.messageEvent.emit(this.options);
  }

  setTheme(theme) {
    this.options.theme = theme;
    this.sendMessage();
  }

  logout(){
    this.authService.doLogout()
    .then((res) => {
      this.location.back();
    }, (error) => {
      console.log("Logout error", error);
    });
  }

}
