import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../core/auth.service'
import { Router, Params } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { WindowService } from '../../core/window.service';
import * as firebase from 'firebase';
import swal from 'sweetalert2'

export interface captchaDemoModel {
  captcha?: string;
}

@Component({
  selector: 'page-login',
  templateUrl: 'login.component.html',
  styleUrls: ['login.scss']
})
export class LoginComponent implements OnInit {
  windowRef: any;
  public formModel: captchaDemoModel = {};

  loginFormPhone: FormGroup;
  loginForm: FormGroup;
  submitted = false;
  public sent: boolean;
  errorMessage: string = '';

  constructor(
    private win: WindowService,
    public authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private fb2: FormBuilder
  ) {
    this.createForm();
    this.createFormPhone();

  }

  public ngOnInit()
  {
    this.windowRef = this.win.windowRef
    this.windowRef.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
    this.windowRef.recaptchaVerifier.render()
    this.createForm();
    this.createFormPhone();

  }

  createForm() {
    this.loginForm = this.fb.group({
      email: ['', Validators.required ],
      password: ['',Validators.required]
    });
  }
  createFormPhone() {
    this.loginFormPhone = this.fb2.group({
      cellnumber:['', Validators.required ],
    });
  }
  tryFacebookLogin(){
    this.authService.doFacebookLogin()
    .then(res => {
      this.router.navigate(['/dashboard']);
    })
  }

  tryTwitterLogin(){
    this.authService.doTwitterLogin()
    .then(res => {
      this.router.navigate(['/dashboard']);
    })
  }

  tryGoogleLogin(){
    this.authService.doGoogleLogin()
    .then(res => {
      this.router.navigate(['/dashboard']);
    })
  }

  tryLogin(value){
    this.authService.doLogin(value)
    .then(res => {
      this.router.navigate(['/dashboard']);
    }, err => {
      console.log(err);
      this.errorMessage = err.message;
    })
  }
  // tryLoginPhone(value){
  //   this.authService.doLogin(value)
  //   .then(res => {
  //     this.router.navigate(['/dashboard']);
  //   }, err => {
  //     console.log(err);
  //     this.errorMessage = err.message;
  //   })
  // }

  tryLoginPhone(value){//value is our parameter passed from ui
    this.submitted = true;
    let countryCode = '+27'
    let phoneNumber = countryCode + value.cellnumber
    const appVerifier = this.windowRef.recaptchaVerifier;
    if (this.loginFormPhone.invalid) {
            return;
        }
        return new Promise<any>((resolve, reject) => {
      firebase.auth().signInWithPhoneNumber(phoneNumber, appVerifier)
      .then( (confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        resolve(confirmationResult);
            this.sent = true;
            swal({
              title: 'Enter OTP Code',
              input: 'text'
            })
            .then(function (otp){
              swal({
                type: 'success',
                html: 'Your code: ' + otp.value
              })
                console.log("Ubunye: " + otp.value);
                confirmationResult.confirm(otp.value)
                  .then((good) => {
                    // all checks out
                    let user = good.user;
                    console.log(user);
                    location.reload();
                    // this.router.push(['/dashboard']);
                  })
               
                  .catch((bad) => {
                    // code verification was bad.
                    console.log(bad);
                    swal({
                      type: 'error',
                      html: 'Error: ' + bad.code //+ "2:" + "3"+bad.message
                    })
                    this.errorMessage = bad.message;
              
                  });
             
            }) 
           
      
    });
    // this.router.navigate(['/dashboard']);
          })
      
      .catch(function (error) {
      // Error; SMS not sent
      // ...
    //  return alert('Error ! SMS not sent')
    })
  

      
  }
}
