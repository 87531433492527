
import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
// import { UserService } from '../user.service';
import { AuthService } from '../auth.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FirebaseUserModel } from '../user.model';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit{
  @Input()
  heading: string;
  @Output()
  toggleSidenav = new EventEmitter<void>();

  user: FirebaseUserModel = new FirebaseUserModel();
  profileForm: FormGroup;

  constructor(
    // public userService: UserService,
    public authService: AuthService,
    private route: ActivatedRoute,
    private location : Location,// enable baack button /
    private fb: FormBuilder
  ) {

  }

  ngOnInit(): void {
    this.route.data.subscribe(routeData => {
      let data = routeData['data'];
      if (data) {
        this.user = data;
        this.createForm(this.user.name);
      }
    })
  }

  createForm(name) {
    this.profileForm = this.fb.group({
      name: [name, Validators.required ]
    });
  }

  // save(value){
  //   this.userService.updateCurrentUser(value)
  //   .then(res => {
  //     console.log(res);
  //   }, err => console.log(err))
  // }

  logout(){
    this.authService.doLogout()
    .then((res) => {
      this.location.back(); // enable baack button /
    }, (error) => {
      console.log("Logout error", error);
    });
  }
}

