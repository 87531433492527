import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from 'angularfire2/auth';
import * as firebase from 'firebase/app';


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth
  ) { }
  ///////////////////////// PROFILE ////////////////////////////////////////////////////////////////////////////////////////////////

  getProfile(userid) {
    //uid parameter passed from profileEditResolver...
    return this.db.collection("stokvelchiefs/").doc(userid).snapshotChanges();
  }

  updateProfile(value) {
    const user = firebase.auth().currentUser;
    return this.db.collection("stokvelchiefs/").doc(user.uid).set(value);
  }

  ///////////////////////////////////////////////////////////////WEIRD STUFF IS GOING ON HERE..... DUNCAN...///////////////////////////////////////////////////////////////////
  getAvatars() {
    return this.db.collection('/avatar').valueChanges()
  }

  // getUserCount(){
  //   const user = firebase.auth().currentUser;
  //   return this.db.collection("stokvelmembers/").doc(user.uid).collection('myStokvelMembers').get().then(res => console.log(res.size))
  //   // return this.db.collection('users').doc(userKey).snapshotChanges();
  // }

  getUser(clubid, memberid) {// in the order defined in resolver class
    const user = firebase.auth().currentUser;
    console.log(clubid + memberid)

    return this.db.collection("stokvelchiefs/").doc(user.uid).collection('myStokvels').doc(clubid).collection('thisStokvelMembers').doc(memberid).snapshotChanges();
    // return this.db.collection("stokvelchiefs/").doc(user.uid).collection('myStokvels').doc(memberKey).snapshotChanges();
    // return this.db.collection('users').doc(userKey).snapshotChanges();
  }

  // ACCOUNTING ////////////////////////////////////////////////////////////////////////

  getAllStokvelChiefs() {
    //uid parameter passed from profileEditResolver...
    return this.db.collection("stokvelchiefs/").snapshotChanges();
  }
  
  getStokvelChief(userid) {
    //uid parameter passed from profileEditResolver...
    return this.db.collection("stokvelchiefs/").doc(userid).snapshotChanges();
  }

  getStokvelChiefStokvels(userid) {
    //uid parameter passed from profileEditResolver...
    return this.db.collection("stokvelchiefs/").doc(userid).collection('myStokvels').snapshotChanges();
  }

  getStokvelChiefStokvel(userid, stokvelid) {
    //uid parameter passed from profileEditResolver...
    return this.db.collection("stokvelchiefs/").doc(userid).collection('myStokvels').doc(stokvelid).snapshotChanges();
  }
  
  updateStokvelChiefStokvel(userid, stokvelid, value) {
    //uid parameter passed from profileEditResolver...
    return this.db.collection("stokvelchiefs/").doc(userid).collection('myStokvels').doc(stokvelid).set(value);
  }

// END ACCOUNTING ////////////////////////////////////////////////////////////////////////

  updateUser(clubid, memberid, value) {
    // value.nameToSearch = value.name.toLowerCase();
    const user = firebase.auth().currentUser;
    return this.db.collection("stokvelchiefs/").doc(user.uid).collection('myStokvels').doc(clubid).collection('thisStokvelMembers').doc(memberid).set(value);
  }

  deleteUser(clubid, memberid) {
    const user = firebase.auth().currentUser;
    return this.db.collection("stokvelchiefs/").doc(user.uid).collection('myStokvels').doc(clubid).collection('thisStokvelMembers').doc(memberid).delete();
  }

  getUsers() {
    const user = firebase.auth().currentUser;
    return this.db.collection("stokvelchiefs/").doc(user.uid).collection('allMyStokvelMembers').snapshotChanges();
    // return this.db.collection("stokvelclubs/").doc(user.uid).collection('myStokvelClubs/').doc(clubid).collection('users').snapshotChanges();
    // return this.db.collection('users').snapshotChanges();
  }
  getUsersGlobal() {
    const user = firebase.auth().currentUser;
    return this.db.collection("allUsers/").snapshotChanges();
  }
  getUsersPerClub(clubid) {
    const user = firebase.auth().currentUser;
    // console.log("duncan" + clubid)
    return this.db.collection("stokvelchiefs/").doc(user.uid).collection('myStokvels').doc(clubid).collection('thisStokvelMembers').snapshotChanges();
    // return this.db.collection("stokvelclubs/").doc(user.uid).collection('myStokvelClubs/').doc(clubid).collection('users').snapshotChanges();
    // return this.db.collection('users').snapshotChanges();
  }
  /////////////////////////////////////////SEARCH USERS/////////////////////////////////////////////////////////////
  searchUsers(searchValue) {
    return this.db.collection('users', ref => ref.where('nameToSearch', '>=', searchValue)
      .where('nameToSearch', '<=', searchValue + '\uf8ff'))
      .snapshotChanges()
  }

  searchUsersByAge(value) {
    return this.db.collection('users', ref => ref.orderBy('age').startAt(value)).snapshotChanges();
  }
  searchUsersByLocatione(value) {
    return this.db.collection('users', ref => ref.orderBy('location').startAt(value)).snapshotChanges();
  }
  searchUsersByIdNumber(value) {
    return this.db.collection('users', ref => ref.orderBy('idnumber').startAt(value)).snapshotChanges();
  }
  searchUsersByPhoneNumber(value) {
    return this.db.collection('users', ref => ref.orderBy('cellnumber').startAt(value)).snapshotChanges();
  }

  /////////////////////////////////////////////////////////////////// USERS ARE MEMBERS
  // createUser(value,clubid){
  createUser(value) {
    const user = firebase.auth().currentUser;
    var userRef = firebase.firestore().collection("stokvelchiefs/").doc(user.uid);
    // var userClubRef = firebase.firestore().collection("stokvelmembers/").doc(user.uid)

    firebase.firestore().collection("allUsers").add({ // 1 // add to all user global node
      initialAddStokvelChiefRef: user.uid,
      name: value.name,
      cellnumber: value.cellnumber,
      idnumber: value.idnumber,
      roles: {
        subscriber: true
      }
      // password: value.password,
      // // userRole:'stokvelmember',  // no need . only necessary for business owners / superusers
      // email: '',
      // idtype:'',
      // idnumber:value.idnumber,
      // address:'',
      // address2:'',
      // province:'',
      // city:'',
      // zip:'',
      // emailverified:'',
      // idnumberVerified:true, // assumption all members added by chief will have doc verified
      // avatar: avatar
    })
      // docref allows you to donice stuff such as getting id of recently created doc then kumnandi..

      .then(function (docRef) { // 2 add  user to all specific stokvel node
        userRef.collection('myStokvels').doc(value.stokvel).collection('thisStokvelMembers').add({
          globalUserRef: docRef.id, // this value will use to query global user
          name: value.name,
          cellnumber: value.cellnumber,
          idnumber: value.idnumber
        });

        firebase.firestore().collection("stokvelchiefs/").doc(user.uid).collection("allMyStokvelMembers").add({ // 3 add to all users for chief node
          globalUserRef: docRef.id,
          stokvel: value.stokvel,
          name: value.name,
          cellnumber: value.cellnumber,
          idnumber: value.idnumber
        });
        console.log("Document(s) written with ID: ", docRef.id);
      })

      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  }
  ///////////////////////////// clubs /////////////////////
  getClubs() {
    const user = firebase.auth().currentUser;
    return this.db.collection("stokvelchiefs/").doc(user.uid).collection('myStokvels').snapshotChanges();
  }

  getClubName(clubKey) {
    const user = firebase.auth().currentUser;
    return this.db.collection("stokvelchiefs/").doc(user.uid).collection('myStokvels').doc(clubKey).snapshotChanges().forEach;
  }
  deleteClub(clubKey) {
    const user = firebase.auth().currentUser;
    return this.db.collection("stokvelchiefs/").doc(user.uid).collection('myStokvels').doc(clubKey).delete();
  }

  createClub(value) {
    const user = firebase.auth().currentUser;
    var userRef = firebase.firestore().collection("stokvelchiefs/").doc(user.uid);
    // var userClubRef = firebase.firestore().collection("stokvelclubs/").doc(user.uid)

    firebase.firestore().collection("allStokvels").add({
      // owner:user.uid, // owner 
      clubname: value.clubname,
      stokveltype: value.stokveltype,
      bankbool: value.bankbool,
      numbermembers: value.numbermembers,
      withdrawaldate: value.withdrawaldate,
      constitutionbool: value.constitutionbool,
      curraccntbool: value.curraccntbool,
    })
      .then(function (docRef) {
        userRef.collection('myStokvels').add({ // just a fun way
          // owner:user.uid, // owner 
          clubname: value.clubname,
          stokveltype: value.stokveltype,
          bankbool: value.bankbool,
          numbermembers: value.numbermembers,
          withdrawaldate: value.withdrawaldate,
          constitutionbool: value.constitutionbool,
          curraccntbool: value.curraccntbool,
          currentbalance:'0.00'
        })
        console.log("Document written with ID: ", docRef.id);
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
    // data flow : stokvelclubs > stokvelchieffbID > mystokvelclubs > [of stokvels registered under]
    //https://stackoverflow.com/questions/48740430/firestore-how-to-get-document-id-after-adding-a-document-to-a-collection
  }

  getClub(clubKey) {//using clubkey - id
    const user = firebase.auth().currentUser;
    return this.db.collection("stokvelchiefs/").doc(user.uid).collection('myStokvels').doc(clubKey).snapshotChanges();
  }

  updateClub(clubKey, value) {
    const user = firebase.auth().currentUser;
    return this.db.collection("stokvelchiefs/").doc(user.uid).collection('myStokvels').doc(clubKey).set(value);
  }

  ///////////////////////// end clubs////////////////////////////////////////////////////////////////////////////////////////////////



  ///////////////////////////// meetings //////////////////////////////////////////////////////////////////////////////////////////
  getMeetings() {
    const user = firebase.auth().currentUser;
    return this.db.collection("stokvelmeetings/").doc(user.uid).collection('myStokvelMeetings').snapshotChanges();

  }

  deleteMeeting(meetingKey) {
    const user = firebase.auth().currentUser;
    return this.db.collection("stokvelmeetings/").doc(user.uid).collection('myStokvelMeetings').doc(meetingKey).delete();
  }

  createMeeting(value) {
    const user = firebase.auth().currentUser;
    var userRef = firebase.firestore().collection("stokvelchiefs/").doc(user.uid);
    var userClubRef = firebase.firestore().collection("stokvelmeetings/").doc(user.uid)
    userClubRef.collection('myStokvelMeetings').add({
      stokvel: value.stokvel,
      title: value.title,
      agenda: value.agenda,
      date: value.date,
      location: value.location,
      notes: value.notes,
      // poster:value.poster,
    })
      .then(function (docRef) {
        console.log("Document written with ID: ", docRef.id);
        userRef.collection('myStokvels').doc(value.stokvel).collection('thisStokvelMeeetings').add({
          stokvel: value.stokvel,
          title: value.title,
          agenda: value.agenda,
          date: value.date,
          location: value.location,
          notes: value.notes,
          // poster:value.poster,
        })
      })
      .catch(function (error) {
        console.error("Error adding document: ", error);
      });
  }

  //   createMeeting(value){
  //     const user = firebase.auth().currentUser;
  //     var userRef = firebase.firestore().collection("stokvelchiefs/").doc(user.uid);
  //     // var userClubRef = firebase.firestore().collection("stokvelmeetings/").doc(user.uid)
  //     // userRef.collection('myStokvelMeetings').add({ 
  //     userRef.collection('myStokvels').doc(value.stokvel).collection('thisStokvelMeeetings').add({ 
  //         stokvel:value.stokvel,  
  //       title:value.title,  
  //       agenda: value.agenda,
  //       date: value.date,
  //       location: value.location,
  //       notes: value.notes,
  //       // poster:value.poster,
  //     })
  //     .then(function(docRef) {
  //       userRef.collection('stokvelmeetings').doc(docRef.id);
  //       console.log("Document written with ID: ", docRef.id);
  // })
  // .catch(function(error) {
  //   console.error("Error adding document: ", error);
  // });
  //   }

  getMeeting(meetingKey) {
    const user = firebase.auth().currentUser;
    return this.db.collection("stokvelmeetings/").doc(user.uid).collection('myStokvelMeetings').doc(meetingKey).snapshotChanges();

  }

  updateMeeting(meetingKey, value) {
    const user = firebase.auth().currentUser;
    return this.db.collection("stokvelmeetings/").doc(user.uid).collection('myStokvelMeetings').doc(meetingKey).set(value);
  }

  ///////////////////////// end clubs
}
